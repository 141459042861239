var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 q-pr-md", attrs: { align: "left" } },
        [
          _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
            _vm._v("Dati generali pratica"),
          ]),
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Cliente:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.name)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della pratica:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getStatoPratica)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero pratica:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.numero)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero interno:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.numero_interno)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Data di decorrenza richiesta:"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getDataDecorrenzaPratica)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Note:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.annotazione)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Unità operativa richiedente:"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Persona operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Telefono Ufficio #1:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.numero_telefono1)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Telefono Ufficio #2:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.numero_telefono2)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Email Ufficio #2:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.dati_ufficio.email)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.haveDatiEconomici,
                  expression: "haveDatiEconomici",
                },
              ],
            },
            [
              _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                _vm._v("Importo totale Pratica"),
              ]),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c(
                            "q-item-label",
                            { staticStyle: { "font-size": "32px" } },
                            [
                              _vm._v(
                                _vm._s(_vm.getPremioTotalePratica) + " Euro"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
          _c("br"),
          _c("br"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-6 row", attrs: { align: "center" } },
        [
          _c(
            "fieldset",
            { staticClass: "q-mb-md", staticStyle: { width: "100%" } },
            [
              _c("legend", { staticClass: "q-pr-md q-pl-md" }, [
                _vm._v(" Informazioni "),
              ]),
              _c("br"),
              _c("QQButton", {
                attrs: {
                  label: "Scheda della Pratica",
                  color: "blue-grey",
                  icon: "mdi-card-account-details-outline",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onClickSchedaPratica()
                  },
                },
              }),
              _c("QQButton", {
                attrs: {
                  disabled: _vm.PraticaAnnullata,
                  label: "Problemi segnalati",
                  color: "blue-grey",
                  icon: "mdi-grid-off",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onProblemiSegnalati()
                  },
                },
              }),
              _c("QQButton", {
                attrs: {
                  disabled: !_vm.PraticaDefinita,
                  label: "Documenti",
                  color: "blue-grey",
                  icon: "mdi-briefcase-upload-outline",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onDocumentiContratto()
                  },
                },
              }),
              _c("QQButton", {
                attrs: {
                  label: "Pagamenti e Ricevute",
                  color: "blue-grey",
                  icon: "mdi-credit-card-check-outline",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onPagamentiRicevute()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "fieldset",
            { staticClass: "q-mb-md", staticStyle: { width: "100%" } },
            [
              _c("legend", { staticClass: "q-pr-md q-pl-md" }, [
                _vm._v(" Procedure "),
              ]),
              _c("br"),
              _vm.isSede
                ? _c("QQButton", {
                    attrs: {
                      disabled: _vm.PuoControllareRichiestaEmissione,
                      label: "Controlla richiesta emissione",
                      color: "blue-grey",
                      icon: "mdi-file-tree",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onControlloRichiestaEmissione()
                      },
                    },
                  })
                : _vm._e(),
              _c("QQButton", {
                attrs: {
                  label: "Rigenera Dichiarazioni Contraente",
                  color: "blue-grey",
                  icon: "mdi-file-tree",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRigeneraDichiarazioniContraente()
                  },
                },
              }),
              _vm.isSede
                ? _c("QQButton", {
                    attrs: {
                      disabled: !_vm.PraticaInLavorazione,
                      label: "Definisci pratica",
                      color: "blue-grey",
                      icon: "mdi-check-all",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onDefinisciPratica()
                      },
                    },
                  })
                : _vm._e(),
              _c("QQButton", {
                attrs: {
                  label: "Segnala problema a Sede",
                  color: "blue-grey",
                  icon: "mdi-flag-checkered",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onSegnalaProblemiASede()
                  },
                },
              }),
              _vm.PuoLavorareLaPratica()
                ? _c("QQButton", {
                    attrs: {
                      disabled: !_vm.PraticaDefinita,
                      label: "Rinnova",
                      color: "blue-grey",
                      icon: "mdi-refresh",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onInLavorazione()
                      },
                    },
                  })
                : _vm._e(),
              _vm.PuoLavorareLaPratica()
                ? _c("QQButton", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.PuoCancellareLaPratica(),
                        expression: "PuoCancellareLaPratica()",
                      },
                    ],
                    attrs: {
                      label: "Annulla Pratica",
                      color: "blue-grey",
                      icon: "mdi-delete",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onCancellaPratica()
                      },
                    },
                  })
                : _vm._e(),
              _vm.PuoLavorareLaPratica()
                ? _c("QQButton", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.PuoCancellareLaPratica(),
                        expression: "PuoCancellareLaPratica()",
                      },
                    ],
                    attrs: {
                      label: "Gestione Contratti",
                      color: "blue-grey",
                      icon: "mdi-format-list-checks",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onInLavorazione()
                      },
                    },
                  })
                : _vm._e(),
              _vm.PuoLavorareLaPratica()
                ? _c("QQButton", {
                    attrs: {
                      label: "Cambia stato Pratica",
                      color: "blue-grey",
                      icon: "repeat-variant",
                      size: "md",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onClickCambiaStatoPratica()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-12" },
        [
          _c("q-table", {
            attrs: {
              title: "Elenco dei contratti",
              data: _vm.elenco_contratti,
              columns: _vm.colonne_contratti,
              "row-key": "prodotto_id",
              "wrap-cells": "",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (props) {
                  return [
                    _c(
                      "q-tr",
                      { attrs: { props: props } },
                      [
                        _c("q-th", { attrs: { "auto-width": "" } }),
                        _vm._l(props.cols, function (col) {
                          return _c(
                            "q-th",
                            { key: col.name, attrs: { props: props } },
                            [_vm._v(" " + _vm._s(col.label) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c(
                      "q-tr",
                      { attrs: { props: props } },
                      [
                        _c(
                          "q-td",
                          { attrs: { "auto-width": "" } },
                          [
                            _c("q-btn", {
                              attrs: {
                                size: "sm",
                                color: "accent",
                                round: "",
                                dense: "",
                                icon: props.expand ? "remove" : "add",
                              },
                              on: {
                                click: function ($event) {
                                  props.expand = !props.expand
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("q-td", [_vm._v(_vm._s(props.cols[0].value))]),
                        _c("q-td", [_vm._v(_vm._s(props.cols[1].value))]),
                        _c("q-td", [
                          _vm._v(
                            _vm._s(_vm.TranscodificaData(props.cols[2].value))
                          ),
                        ]),
                        _c("q-td", [
                          _vm._v(
                            _vm._s(_vm.TranscodificaData(props.cols[3].value))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: props.expand,
                            expression: "props.expand",
                          },
                        ],
                        attrs: { props: props },
                      },
                      [
                        _c("q-td", { attrs: { colspan: "100%" } }, [
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v(
                              "Quietanze su contratto " +
                                _vm._s(props.row.prodotto_id) +
                                "."
                            ),
                          ]),
                          _c("br"),
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c(
                              "tbody",
                              [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Fornitore")]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Decorrenza Rata")]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Scadenza Rata")]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Data di Incasso")]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Costo Rata")]
                                  ),
                                  _c(
                                    "td",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Incasso Rata")]
                                  ),
                                  _vm.isSede ? _c("td") : _vm._e(),
                                  _c("td", [
                                    _vm._v("Ricevuta pagamento Quietanza"),
                                  ]),
                                ]),
                                _vm._l(
                                  _vm.elencoQuietanze(props.row.prodotto_id),
                                  function (quietanza, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(_vm._s(quietanza.fornitore)),
                                      ]),
                                      _c("td", { attrs: { align: "center" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.TranscodificaData(
                                              quietanza.decorrenza
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", { attrs: { align: "center" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.TranscodificaData(
                                              quietanza.scadenza
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", { attrs: { align: "center" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.TranscodificaData(
                                              quietanza.data_incasso
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(
                                          _vm._s(quietanza.premio) + " Euro"
                                        ),
                                      ]),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(
                                          _vm._s(quietanza.totale) + " Euro"
                                        ),
                                      ]),
                                      _vm.isSede
                                        ? _c(
                                            "td",
                                            {
                                              attrs: {
                                                align: "center",
                                                width: "220",
                                              },
                                            },
                                            [
                                              quietanza.da_definire
                                                ? _c("q-btn", {
                                                    staticStyle: {
                                                      width: "200px",
                                                    },
                                                    attrs: {
                                                      color: "deep-orange",
                                                      glossy: "",
                                                      label: "DEFINISCI",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.onDefinisciQuietanza(
                                                          props.cols[1].value,
                                                          quietanza
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            align: "center",
                                            width: "220",
                                          },
                                        },
                                        [
                                          _c("q-btn", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              color: "deep-orange",
                                              glossy: "",
                                              label: "Stampa Ricevuta",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.onStampaRicevuta(
                                                  props.cols[1].value,
                                                  quietanza
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onClickIndietro()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Menu gestione Pratica")]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }